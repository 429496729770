import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AdminLogin from "./pages/adminLogin/AdminLogin.jsx";
import Authentication from "./pages/adminLogin/Authentication.jsx";
import DashboardContent from "./components/dashboardSections/emptyState/DashboardContent.jsx";
import Help from "./pages/HelpSection/Help.jsx";
import Logout from "./components/Logout.jsx";
import RulesSection from "./pages/RulesSection/index.jsx";
import SettlementSection from "./pages/SettlementSection/index.jsx";
import PriorityIndex from "./pages/PriorityIndex.jsx";
import DashboardActiveContent from "./components/dashboardSections/activeState/DashboardActiveContent";
// import AddVendors from './pages/components/All-Pages/RulesSection/AddVendors';
import Dashboard from "./pages/admin_dashboard/sidebar/Main.jsx";
import TimeSection from "./pages/RulesSection/RuleConfigSection/TimeSection";
import BankSection from "./pages/RulesSection/RuleConfigSection/BankSection";
import AddAllVendors from "./pages/RulesSection/VendorSection/VendorsList";
import ProtectedRoutes from "./components/ProtectedRoute";
import AuthProtected from "./components/AuthProtected";
import RulesApproval from "./pages/RulesApproval";
import SettlementApproval from "./pages/SettlementApproval";
import UserApproval from "./pages/UserApproval";
import Users from "./pages/Users/Users";
import AddSystemUser from "./pages/Users/AddSystemUser";
import Settings from "./pages/Settings";
import RuleHistory from "./pages/RuleHistory";
import SettlementHistory from "./pages/SettlementHistory";
import UnauthorizedError from "./pages/UnauthorizedError";
import Error404 from "./pages/404";
import ActivityLog from "./pages/ActivityLog";
import TextEncryption from "./pages/TextEncryption";
import PageAccess from "./pages/PageAccess";

import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route>
          <Route path="/" element={<Navigate to="/auth/login" />} />
          <Route exact path="/auth/login" element={<AdminLogin />} />
          <Route exact path="/text-encryption" element={<TextEncryption />} />
          <Route element={<AuthProtected />}>
            <Route
              exact
              path="/auth/authentication"
              element={<Authentication />}
            />
          </Route>

          <Route element={<ProtectedRoutes />}>
            <Route exact path="/admin" element={<Dashboard />}>
              <Route
                exact
                path="/admin/dashboard"
                element={<DashboardContent />}
              />
              <Route
                exact
                path="/admin/dashboard-active"
                element={<DashboardActiveContent />}
              />
              {/* <Route exact path='/admin/added-vendors' element={<AddVendors />} /> */}
              <Route
                exact
                path="/admin/configuration/time-section"
                element={<TimeSection />}
              />
              <Route
                exact
                path="/admin/rules-approval"
                element={<RulesApproval />}
              />
              <Route
                exact
                path="/admin/user-approval"
                element={<UserApproval />}
              />
              <Route
                exact
                path="/admin/system-user"
                element={<AddSystemUser />}
              />
              <Route
                exact
                path="/admin/configuration/bank-section"
                element={<BankSection />}
              />
              <Route
                exact
                path="/admin/add-all-vendors"
                element={<AddAllVendors />}
              />
              <Route
                exact
                path="/admin/configuration"
                element={<RulesSection />}
              />
              <Route
                exact
                path="/admin/settlement"
                element={<SettlementSection />}
              />
              <Route
                exact
                path="/admin/settlement-approval"
                element={<SettlementApproval />}
              />
              <Route
                exact
                path="/admin/settlement-history"
                element={<SettlementHistory />}
              />
              <Route
                exact
                path="/admin/rule-history"
                element={<RuleHistory />}
              />
              <Route exact path="/admin/piority" element={<PriorityIndex />} />
              <Route exact path="/admin/settings" element={<Settings />} />
              <Route exact path="/admin/users" element={<Users />} />
              <Route exact path="/admin/activity" element={<ActivityLog />} />
              <Route exact path="/admin/page-access" element={<PageAccess />} />
              <Route exact path="/admin/help" element={<Help />} />
              <Route exact path="/admin/logout" element={<Logout />} />
              <Route
                exact
                path="/admin/error"
                element={<UnauthorizedError />}
              />
              <Route exact path="/admin/*" element={<Error404 />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
