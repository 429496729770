import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import update from "immutability-helper";
import { toast } from "react-toastify";
import "../../styles/priorityIndex.css";

import { Card } from "./card";
import { priorityService, vendorService } from "../../utils/api";

import Polygon from "../../assets/svg/polygon.svg";
import Profile from "../../assets/svg/profile.svg";
//import ProfileColor from "../../assets/svg/profileColor.svg";

import TimerIcon from "@mui/icons-material/Timer";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";

const PriorityIndexComp = () => {
  const [priorityList, setPriorityList] = useState([]);
  const [vendorInfo, setVendorInfo] = useState([]);
  const [, setVendorListHover] = useState(false);
  const actorEmail = JSON.parse(sessionStorage.getItem("user")).email;

  const priorityItemList = async () => {
    try {
      const response = await priorityService.getPriority();
      const sortedData = response.data.sort((a, b) => a.priority - b.priority);
      setPriorityList(sortedData);
    } catch (error) {
      console.log(error);
    }
  };
  const updatePriority = useCallback(async () => {
    try {
      const payload = priorityList?.map((item, index) => ({
        ...item,
        priority: index + 1,
      }));
      const response = await priorityService.updatePriority(
        actorEmail,
        payload
      );
      if (response?.status === 200) {
        toast.success("Rule sent for approval successfully", {
          className: "toast-success",
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong.", {
          className: "toast-error",
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error("Something went wrong.", {
        className: "toast-error",
        theme: "colored",
      });
    }
  }, [priorityList, actorEmail]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await vendorService.getAllVendors();
      setVendorInfo(data);
    };
    priorityItemList();
    fetchData();
  }, []);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setPriorityList((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const renderCard = useCallback(
    (card, index) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          text={card.name}
          moveCard={moveCard}
          updatePriority={updatePriority}
        />
      );
    },
    [moveCard, updatePriority]
  );

  return (
    <div className="dashboard-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "94%",
        }}
      >
        <div>
          <h2 className="priority-header">Priority Index</h2>
        </div>
        <div style={{ display: "flex", columnGap: "20px" }}>
          <div className="time">
            <TimerIcon />
            <p>Time: {moment().format("h:mm:ss a")} </p>
          </div>
          <div>
            <Box sx={{ color: "action.active", marginTop: "4px" }}>
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent=""
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </Box>
          </div>
        </div>
      </div>

      <div style={{ width: "96%" }}>
        <p className="priority-header-text">
          Configure routing rules to automatically allow system detect customer
          actions by enabling the set configured rules
        </p>
      </div>

      <div className="priority-content-container">
        <div>
          <img src={Polygon} alt="polygon" className="priority-content-img" />
        </div>
        <div className="priority-card-label">
          <div className="priority-card-label-title">
            <h4>Priority Index (Rules)</h4>
          </div>
          <div className="priority-card">
            {priorityList?.map((card, i) => renderCard(card, i))}
          </div>
          <div className="priority-card-label-container">
            {priorityList?.map((item) => {
              return (
                <h3 className="priority-card-label-text">{item.priority}</h3>
              );
            })}
          </div>
        </div>
        <div className="priority-vendor-container">
          <div className="priority-vendor-list-container">
            {vendorInfo?.map((item) => {
              return (
                <div
                  key={item.id}
                  className="vendor-list"
                  onClick={() => setVendorListHover((prev) => !prev)}
                >
                  <img src={Profile} alt="icon" />
                </div>
              );
            })}
          </div>
          <h4 className="priority-vendor-details-text">All Vendors</h4>
        </div>
      </div>
      <p className="copyright-text">&copy; 2024 Breeze Transaction Gateway. </p>
    </div>
  );
};

export default PriorityIndexComp;
